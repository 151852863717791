import { useQueries, UseQueryResult } from '@tanstack/react-query';
import classiciSeriesService from '../../../../services/classiciSeriesService';
import { PrecancelStatus } from '@legalshield/frontend-commons/dist/sdk/atlas-iseries';

type PrecancelStatusQuery = UseQueryResult<PrecancelStatus[], unknown>;

type PrecancelStatusQueriesMap = Record<string, PrecancelStatusQuery>;

const getPrecancelStatuses = async (membershipId: string): Promise<PrecancelStatus[]> => {
  return classiciSeriesService.getPrecancelStatuses(membershipId);
};

export function useGetMultiplePrecancelStatuses(membershipIds: string[] = []): PrecancelStatusQueriesMap {
  const precancelStatusQueriesArray = useQueries({
    queries: membershipIds.map((id) => ({
      queryKey: ['precancelStatus', id],
      queryFn: () => getPrecancelStatuses(id),
      enabled: Boolean(id),
    })),
  });

  const precancelStatusQueriesMap: PrecancelStatusQueriesMap = {};
  membershipIds.forEach((id, index) => {
    precancelStatusQueriesMap[id] = precancelStatusQueriesArray[index];
  });

  return precancelStatusQueriesMap;
}
